import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import ContentimientoScreen from "./pages/consentimiento/consentimiento";
import ContentimientoOKScreen from "./pages/consentimiento/consentimientoOK";
import ContentimientoKOScreen from "./pages/consentimiento/consentimientoKO";
import RecuperarPassScreen from "./pages/seguridad/recuperarPass";
import ContentimientoUsadoScreen from "./pages/consentimiento/consentimientoUsado";
import CambioPassOKScreen from "./pages/seguridad/cambioPassOK";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="consentimiento/*" element={<ContentimientoScreen />} />
        <Route path="finalizarok/*" element={<ContentimientoOKScreen />} />
        <Route path="finalizarko/*" element={<ContentimientoKOScreen />} />
        <Route path="invalidtoken/*" element={<ContentimientoUsadoScreen />} />
        <Route path="reset_password" element={<RecuperarPassScreen />} />
        <Route path="reset_password_ok" element={<CambioPassOKScreen />} />
      </Routes>
    </BrowserRouter>
  );
}

function Home() {
  return <h2>Home</h2>;
}
