
import React, {useEffect, useState} from "react";
import { Container, Row, Col } from 'react-grid-system';
import { documents } from "../../backend/functions";

import logo from  '../../assets/LogoClick2Consent.png'
import framekogrande from  '../../assets/Framekogrande.png'
import gsotore from '../../assets/GStore.jpg'
import asotore from '../../assets/AStore.jpg'
import '../../App.css'

function ContentimientoKOScreen() {
    const [token, setToken] = useState(null)
    const [document, setDocument] = useState(null)

    useEffect(() => {    
        const queryParams = new URLSearchParams(window.location.search);
        /*
        setToken(queryParams.get('data'))
        documents.getexternaldoc({datos: queryParams.get('data')})
        .then(response => {
            setDocument(response.data)
        })
        */
    }, []);

    return (
        <div className="App">
            <header className="App-header" style={{alignItems:'start'}}>
                
                        {
                            <>
                                <Container style={{padding:'0 32px'}}>
                                    <Row>
                                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{marginTop: 44}}>
                                            <img alt="logo" src={logo} width={212} height={54}/>
                                        </Col>
                                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{marginTop: 32}}>
                                            <p style={{marginTop: 0}} className="App-text-bold-black">{`Tu decisión de declinar el consentimiento se ha registrado correctamente.`}</p>
                                        </Col>
                                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{marginTop: 36}}>
                                            <img alt="logo" src={framekogrande} width={72} height={70.55}/>
                                        </Col>
                                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{marginTop: 36}}>
                                            <p style={{marginTop: 0}} className="App-text-500-black">{`No olvides descargar nuestra App para realizar el seguimiento del estado de este documento, recibir notificaciones directas en tu dispositivo y  aprovechar al máximo todas gestiones de tus documentos. `}</p>
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop: 37, marginBottom: 44}}>
                                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6} style={{alignItems: 'center', alignContent: 'center', textAlign: 'center'}}>
                                            <img alt="logo" src={gsotore} width={135} height={40} />
                                        </Col>
                                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6} style={{alignItems: 'center', alignContent: 'center', textAlign: 'center'}}>
                                            <img alt="logo" src={asotore} width={135} height={40} />
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop: 37, marginBottom: 44}}>
                                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{marginTop: 36}}>
                                            <p style={{marginTop: 0}} className="App-text-500-black"><b>{`Esta aplicación se encuentra en modo de pruebas, para descargar la App utilice:`}</b></p>
                                        </Col>
                                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{marginTop: 10}}>
                                            <p style={{marginTop: 0}} className="App-text-500-black">{`Si tiene la aplicación de Expo Go Instalada, pruebe el siguente enlace`}</p>
                                            <a href="exp://exp.host/@smartbits/we-consent" target={'_blank'}>Abir Expo Go</a>
                                        </Col>
                                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{marginTop: 36}}>
                                            <p style={{marginTop: 0}} className="App-text-500-black">{`Si NO tiene la aplicación de Expo Go Instalada, siga las instrucciones del siguente enlace`}</p>
                                            <a href="https://expo.dev/@smartbits/we-consent" target={'_blank'}>Instrucciones de descarga</a>
                                        </Col>
                                    </Row>                                     
                                </Container>
                            </>
                        }
                
            </header>
        </div>
    );
}

export default ContentimientoKOScreen;