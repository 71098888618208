
import React, { useEffect, useState } from "react";
import { useNavigate} from "react-router-dom";
import { Container, Row, Col } from 'react-grid-system';

import logo from '../../assets/LogoClick2Consent.png'
import frameokgrande from '../../assets/Frameokgrande.png'
import gsotore from '../../assets/GStore.jpg'
import asotore from '../../assets/AStore.jpg'
import { users } from "../../backend/functions";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../App.css'

function RecuperarPassScreen() {
    const history = useNavigate ();
    const [email, setEmail] = useState('')
    const [pass, setPass] = useState('')
    const [confirmPass, setConfirmPass] = useState('')
    const [token, setToken] = useState('')

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        
        setToken(queryParams.get('token'))
    }, []);

    const notify = (type, msg) => toast(msg, {type: type});

    const clickBtn = () => {
        if(pass !== confirmPass){
            notify('error', `Las contraseñas no coinciden`)
            return false
        }
        users.resetPass({
            token: token,
            password: pass
        })
        .then(response => {
            history('/reset_password_ok')
        })
        .catch(error => {
            if(error.response.data){
                const err = error.response.data
                if(err.password){
                    err.password.map(msg => {
                        if(msg === "This password is too short. It must contain at least 8 characters."){
                            notify('error', `El campo Contraseña debe rellenarlo con al menos 8 carácteres.`)
                        }else if(msg === "This password is too common."){
                            notify('error', `La contraseña es muy común`)
                        }else if(msg === "This password is entirely numeric."){
                            notify('error', `La contraseña es totalmente numérica`)
                        }else if(msg === "The password is too similar to the username."){
                            notify('error', `La contraseña es muy similar al nombre de usuario`)
                        }else{
                            notify('error', `Este token ya ha sido usado`)
                        }
                    })
                }
            }
        })
    }

    return (
        <div className="App">
            <header className="App-header" style={{ alignItems: 'start', display:'block' }}>

                {
                    <>
                        <Container style={{ padding: '0 32px' }}>
                            <ToastContainer />
                            <Row>
                                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginTop: 44 }}>
                                    <img alt="logo" src={logo} width={212} height={54} />
                                </Col>
                                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginTop: 32 }}>
                                    <p style={{ margin: 0 }} className="App-text-color-18">{`Cambio de contraseña`}</p>
                                </Col>
                                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginTop: 27 }}>
                                    <p style={{ margin: 0 }} className="App-text-500-black">{`Para restaurar tus claves de acceso por rellena los siguientes campos.`}</p>
                                </Col>
                                {
                                    /**
                                    
                                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'left', marginTop: 27 }}>
                                    <p className="App-text-color-15-input">{`Correo electrónico`}</p>
                                    <input type={'email'} placeholder="robertoarenas@gmail.com" onChange={(value) => setEmail(value.target.value)}></input>
                                </Col>
                                 * 
                                */
                                }
                                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'left', marginTop: 14 }}>
                                    <p className="App-text-color-15-input">{`Nueva contraseña`}</p>
                                    <input type={'password'} placeholder="Abcde123456" onChange={(value) => setPass(value.target.value)}></input>
                                </Col>
                                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'left', marginTop: 14 }}>

                                    <p className="App-text-color-15-input">{`Repetir nueva contraseña`}</p>
                                    <input type={'password'} placeholder="Abcde123456" onChange={(value) => setConfirmPass(value.target.value)}></input>
                                </Col>
                            </Row>

                        </Container>
                        <div class="div_botones">
                            <button class="btn_enviar" type="button" onClick={() => clickBtn()}>{`Enviar`}</button>
                            <button class="btn_cancelar" type="button">{`Cancelar`}</button>
                        </div>
                    </>
                }

            </header>
        </div>
    );
}

export default RecuperarPassScreen;