import axios from 'axios'
export const url_backend = `https://weconsentbackend.devsmb.es`

export const documents = {
    getexternaldoc: function(data){
        return axios.post(`${url_backend}/documents/getexternaldoc/`, data)
    },
    singviasms: function(data){
        return axios.post(`${url_backend}/documents/singviasms/`, data)
    }
}

export const users = {
    signup: function(data){
        return axios.post(`${url_backend}/users/signup/`, data)
    },
    resetPass: function(data){
        return axios.post(`${url_backend}/reset-password/confirm/`, data)
    }
}
