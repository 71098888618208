
import React, {useEffect, useState} from "react";
import { Container, Row, Col } from 'react-grid-system';
import { documents } from "../../backend/functions";

import logo from  '../../assets/LogoClick2Consent.png'
import frameokgrande from  '../../assets/Frameokgrande.png'
import gsotore from '../../assets/GStore.jpg'
import asotore from '../../assets/AStore.jpg'
import readerCheck from '../../assets/reader_check_2.png'
import '../../App.css'

function CambioPassOKScreen() {
    const [token, setToken] = useState(null)
    const [document, setDocument] = useState(null)

    useEffect(() => {    
        const queryParams = new URLSearchParams(window.location.search);
        /*
        setToken(queryParams.get('data'))
        documents.getexternaldoc({datos: queryParams.get('data')})
        .then(response => {
            setDocument(response.data)
        })
        */
    }, []);

    return (
        <div className="App">
            <header className="App-header" style={{alignItems:'start'}}>
                
                        {
                            <>
                                <Container style={{padding:'0 32px'}}>
                                    <Row>
                                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{marginTop: 44}}>
                                            <img alt="logo" src={logo} width={212} height={54}/>
                                        </Col>
                                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{marginTop: 32}}>
                                            <p style={{marginTop: 0}} className="App-text-bold-black">{`Contraseña restaurada`}</p>
                                        </Col>
                                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{marginTop: 36}}>
                                            <img alt="logo" src={readerCheck} width={150} height={150}/>
                                        </Col>
                                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{marginTop: 36}}>
                                            <p style={{marginTop: 0}} className="App-text-500-black">{`Tu contraseña ha sido restaurada con éxito.`}</p>
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop: 37, marginBottom: 44}}>
                                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                            <button class="btn_enviar" type="button" onClick={() => window.open('exp://exp.host/@smartbits/we-consent')}>{`Abrir aplicación`}</button>
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop: 37, marginBottom: 44}}>
                                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{marginTop: 36}}>
                                            <p style={{marginTop: 0}} className="App-text-500-black"><b>{`Esta aplicación se encuentra en modo de pruebas, para descargar la App utilice:`}</b></p>
                                        </Col>
                                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{marginTop: 10}}>
                                            <p style={{marginTop: 0}} className="App-text-500-black">{`Si tiene la aplicación de Expo Go Instalada, pruebe el siguente enlace`}</p>
                                            <a href="exp://exp.host/@smartbits/we-consent" target={'_blank'}>Abir Expo Go</a>
                                        </Col>
                                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{marginTop: 36}}>
                                            <p style={{marginTop: 0}} className="App-text-500-black">{`Si NO tiene la aplicación de Expo Go Instalada, siga las instrucciones del siguente enlace`}</p>
                                            <a href="https://expo.dev/@smartbits/we-consent" target={'_blank'}>Instrucciones de descarga</a>
                                        </Col>
                                    </Row>                                   
                                </Container>
                            </>
                        }
                
            </header>
        </div>
    );
}

export default CambioPassOKScreen;