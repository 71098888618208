
import React, {useEffect, useState} from "react";
import { Container, Row, Col } from 'react-grid-system';
import { documents, url_backend } from "../../backend/functions";

import logo from  '../../assets/LogoClick2Consent.png'
import frameok from  '../../assets/Frameok.png'
import frameko from  '../../assets/Frameko.png'
import check from  '../../assets/check.png'
import documentoPre from  '../../assets/Documentopre.png'
import '../../App.css'
import moment from "moment";
import { useNavigate} from "react-router-dom";
import ReactLoading from 'react-loading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

moment.locale('es')
function ContentimientoScreen() {
    const history = useNavigate ();
    const [token, setToken] = useState(null)
    const [document, setDocument] = useState(null)

    /**
     * Parametros de nuevo usuario form
     */
    const [first_name, setFirstName] = useState('')
        const [primer_app, setPrimerApp] = useState('')
        const [segun_app, setSegunApp] = useState('')
    const [idcard, setIdCard] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [checkbox, setCheckbox] = useState(false)

    const [verCargando, setVerCargando] = useState(false)


    useEffect(() => {    
        const queryParams = new URLSearchParams(window.location.search);
        setToken(queryParams.get('data'))
        documents.getexternaldoc({datos: queryParams.get('data')})
        .then(response => {
            setDocument(response.data)
            setPhone(response.data.user.phone)
        })
        .catch(err => {
            console.error(err.response.data)
            if(err.response.data.status === "El token no es válido o ya ha sido usado"){
                history(`/invalidtoken/?data=${token}`)
            }
        })
    }, []);

    const notify = (type, msg) => toast(msg, {type: type});

    function validateDNI(dni) {
        var numero, lett, letra;
        var expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/;
    
        dni = dni.toUpperCase();
    
        if(expresion_regular_dni.test(dni) === true){
            numero = dni.substr(0,dni.length-1);
            numero = numero.replace('X', 0);
            numero = numero.replace('Y', 1);
            numero = numero.replace('Z', 2);
            lett = dni.substr(dni.length-1, 1);
            numero = numero % 23;
            letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
            letra = letra.substring(numero, numero+1);
            if (letra != lett) {
                //alert('Dni erroneo, la letra del NIF no se corresponde');
                return false;
            }else{
                //alert('Dni correcto');
                return true;
            }
        }else{
            //alert('Dni erroneo, formato no válido');
            return false;
        }
    }

    const pressBtn = async (signed) => {
        setVerCargando(true)
        if(!checkbox){
            notify('error', `Debes aceptar las Condiciones del servicio y Política de privacidad.`)
            setVerCargando(false)
            return true
        }

        if(password !== passwordConfirm){
            notify('error', `Las contraseñas no coinciden`)
            setVerCargando(false)
            return true
        }

        if(!validateDNI(idcard)){
            notify('error', `Debe introducir un DNI válido`)
            setVerCargando(false)
            return true
        }
        
        
        await documents.singviasms({
            "email": email,
            "password": password,
            "password_confirmation": passwordConfirm,
            "first_name": first_name,
            "last_name": `${primer_app} ${primer_app}`,
            "phone": phone,
            "username": `${first_name.split(' ')[0]}_${Math.round(Math.random() * (10000 - 1000) + 1000)}`,
            "idcard": idcard,
            "token": token,
            "signed": signed
        })
        .then((response) => {
            setVerCargando(false)
            console.info(response.data)
            if(!signed){
                history(`/finalizarko/?data=${token}`)
            }else{
                history(`/finalizarok/?data=${token}`)
            }
        })
        .catch((error) => {
            setVerCargando(false)
            if(error.response.data){
                const err = error.response.data
                console.info(error.response)
                if(err.first_name){
                    notify('error', `El campo Nombre es obligatorio.`)
                }
                if(err.last_name){
                    notify('error', `El campo Primer Apellido es obligatorio.`)
                }
                if(err.idcard){
                    notify('error', `El campo DNI o Pasaporte es obligatorio.`)
                }
                if(err.email){
                    console.info(err.email)
                    err.email.map(msg => {
                        if(msg === "Enter a valid email address."){
                            notify('error', `El campo Correo electrónico debe rellenarlo con un correo válido.`)
                        }else{
                            notify('error', `El campo Correo electrónico es obligatorio.`)
                        }
                    })
                }
                if(err.password){
                    err.password.map(msg => {
                        if(msg === "Ensure this field has at least 4 characters."){
                            notify('error', `El campo Contraseña debe rellenarlo con al menos 4 carácteres.`)
                        }else{
                            notify('error', `El campo Contraseña es obligatorio.`)
                        }
                    })
                }
                if(err.non_field_errors){
                    notify('error', `Las contraseñas no coinciden`)
                }
            }
            
            console.error(error.response.data)
        })
        
    }

    return (
        <>
            {
                document !== null && 
                <>
                    
                        <div className="App">
                            <header>
                                <Container style={{padding:'0 42px'}}>
                                    <ToastContainer />
                                    <Row>
                                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{marginTop: 44}}>
                                            <img alt="logo" src={logo} width={212} height={54}/>
                                        </Col>
                                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                            <p className="App-text-bold-black">{`Hola ${document.user.name},`}</p>
                                            <p className="App-text-bold-black">{`Este documento es para ti`}</p>
                                        </Col>
                                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                            <p className="App-text-500-black">{`Tu amigo ${document.document.user.first_name} ${document.document.user.last_name}, te ha enviado el siguiente documento para que des tu consentimiento.`}</p>
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop: 30}}>
                                        {/* <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}>
                                            <img alt="logo" src={frameok} width={25} height={'24.5px'}/>
                                        </Col> */}
                                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{paddingLeft:20}}>
                                            <img alt="logo" src={frameok} width={25} height={'24.5px'} style={{verticalAlign:'top', marginTop: 2}}/>
                                            <p style={{display:'inline-block', width:'calc(100% - 35px)', marginLeft:10, textAlign:'left'}} className="App-text-normal-14">{`Si estás de acuerdo por favor confirma los siguientes datos y pulsa en el botón enviar.`}</p>
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop: 25}}>
                                        {/* <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}>
                                            <img alt="logo" src={frameko} width={25} height={'24.5px'}/>
                                        </Col> */}
                                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{paddingLeft:20}}>
                                            <img alt="logo" src={frameko} width={25} height={'24.5px'} style={{verticalAlign:'top', marginTop: 2}}/>
                                            <p style={{display:'inline-block', width:'calc(100% - 35px)', marginLeft:10, textAlign:'left'}} className="App-text-normal-14">{`Si por el contrario quieres declinarlo, simplemente pulsa sobre el botón Rechazar.`}</p>
                                        </Col>
                                    </Row> 
                                    <Row style={{marginTop: 25}}>
                                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                            <p className="App-text-color-18">{`Información del documento`}</p>
                                        </Col>
                                    </Row>  
                                    <Row style={{marginTop: 0}}>
                                        <Col xxl={3} xl={3} lg={3} md={3} sm={3} xs={3}>
                                            <p className="App-text-color-15">{`Datos`}</p>
                                        </Col>
                                        <Col xxl={9} xl={9} lg={9} md={9} sm={9} xs={9} style={{textAlign: 'left'}}>
                                            <p className="App-text-datos-13">{`${document.document.name}`}</p>
                                            <hr></hr>
                                            <p className="App-text-datos-13">{`Validez ${document.document.type_validity}`}</p>
                                            <hr></hr>
                                            <p className="App-text-datos-13">{`Desde ${moment(document.document.date_start).subtract('hours', 1).format('DD/MM/YYYY HH:mm')}`}</p>
                                            <hr></hr>
                                            {
                                                document.document.type_validity !== 'permanente' && (
                                                    <>
                                                        <p className="App-text-datos-13">{`Hasta ${moment(document.document.date_end).subtract('hours', 1).format('DD/MM/YYYY HH:mm')}`}</p>
                                                        <hr></hr>
                                                    </>
                                                )
                                            }
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop: 27}}>
                                        <Col xxl={3} xl={3} lg={3} md={3} sm={3} xs={3}>
                                            <p className="App-text-color-15">{`Fecha`}</p>
                                        </Col>
                                        <Col xxl={9} xl={9} lg={9} md={9} sm={9} xs={9} style={{textAlign: 'left'}}>
                                            {
                                                document.signatories_pend.map((signatory, index) => {
                                                    if(signatory.user_pre.id === document.user.id){
                                                        return (
                                                            <>
                                                            <p className="App-text-datos-13">{`Enviado: ${moment(signatory.date_notified).format('L')}`}</p>
                                                            <hr></hr>
                                                            </>
                                                        )
                                                    }
                                                    return false
                                                })
                                            }
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop: 27}}>
                                        <Col xxl={3} xl={3} lg={3} md={3} sm={3} xs={3}>
                                            <p className="App-text-color-15">{`Firmantes`}</p>
                                            <p className="App-text-datos-22-bold-black">{`${document.signatories.length+document.signatories_pend.length}`}</p>
                                        </Col>
                                        <Col xxl={9} xl={9} lg={9} md={9} sm={9} xs={9} style={{textAlign: 'left'}}>
                                            {
                                                document.signatories.map((signatory, index) => {
                                                    return (
                                                        <>
                                                            <p className="App-text-datos-13">{`${signatory.user.first_name} ${signatory.user.last_name}`} {signatory.is_signed && (<img alt="logo" src={check} width={16.39} height={9.35}/>)}</p>
                                                            <hr></hr>
                                                        </>
                                                    )
                                                })
                                            }
                                            {
                                                document.signatories_pend.map((signatory, index) => {
                                                    return (
                                                        <>
                                                            <p className="App-text-datos-13">{`${signatory.user_pre.name}`}</p>
                                                            <hr></hr>
                                                        </>
                                                    )
                                                         })
                                            }
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop: 24}}>
                                        <Col xxl={3} xl={3} lg={3} md={3} sm={3} xs={3}>
                                            <p className="App-text-color-15">{`Documento`}</p>
                                        </Col>
                                        <Col xxl={9} xl={9} lg={9} md={9} sm={9} xs={9} >
                                            <img alt="logo" style={{cursor: 'pointer'}} src={documentoPre} width={152} height={190} onClick={() => window.open(`${url_backend}/${document.document.document}`, '_blank').focus()} />
                                        </Col>
                                    </Row> 
                                    <Row style={{marginTop: 24}}>
                                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                            <p className="App-text-color-18-normal" style={{marginBottom: 5}}>{`Si estás de acuerdo confirma tus datos`}</p>
                                        </Col>
                                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                            <p className="App-text-color-18" style={{marginTop: 5}}>{`Tus datos personales`}</p>
                                        </Col>
                                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{textAlign: 'left', marginBottom: 14}}>
                                            <p className="App-text-color-15-input">{`Nombre`}</p>
                                            <input placeholder="Nombre" value={first_name} onChange={(value) => setFirstName(value.target.value)}></input>
                                        </Col>
                                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{textAlign: 'left', marginBottom: 14}}>
                                            <p className="App-text-color-15-input">{`Primer apellido`}</p>
                                            <input placeholder="Apellido" value={primer_app} onChange={(value) => setPrimerApp(value.target.value)}></input>
                                        </Col>
                                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{textAlign: 'left', marginBottom: 14}}>
                                            <p className="App-text-color-15-input">{`Segundo apellido`}</p>
                                            <input placeholder="Apellido" value={segun_app} onChange={(value) => setSegunApp(value.target.value)}></input>
                                        </Col>
                                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{textAlign: 'left', marginBottom: 14}}>
                                            <p className="App-text-color-15-input">{`DNI o Pasaporte`}</p>
                                            <input placeholder="Dni o pasaporte" value={idcard} onChange={(value) => setIdCard(value.target.value)}></input>
                                        </Col>
                                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{textAlign: 'left', marginBottom: 14}}>
                                            <p className="App-text-color-15-input">{`Correo electrónico`}</p>
                                            <input type='email' placeholder="xxxxxxxxxx@gmail.com" value={email} onChange={(value) => setEmail(value.target.value)}></input>
                                        </Col>
                                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{textAlign: 'left', marginBottom: 14}}>
                                            <p className="App-text-color-15-input">{`Teléfono móvil`}</p>
                                            <input placeholder="672839016" value={phone} disabled={true}></input>
                                        </Col>
                                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{textAlign: 'left', marginBottom: 14}}>
                                            <p className="App-text-color-15-input">{`Contraseña`}</p>
                                            <input type='password' placeholder="*****************" value={password} onChange={(value) => setPassword(value.target.value)}></input>
                                        </Col>
                                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{textAlign: 'left', marginBottom: 14}}>
                                            <p className="App-text-color-15-input">{`Repetir contraseña`}</p>
                                            <input type='password' placeholder="*****************" value={passwordConfirm} onChange={(value) =>  setPasswordConfirm(value.target.value)}></input>
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop: 14}}>
                                        <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}>
                                            <input type="checkbox" name="checkbox" checked={checkbox} onChange={(value) => setCheckbox(value.target.checked)} />
                                        </Col>
                                        <Col xxl={10} xl={10} lg={10} md={10} sm={10} xs={10} >
                                            <p className="App-text-datos-12">{`Al registrarme, acepto las Condiciones del servicio y Política de privacidad.`}</p>
                                        </Col>
                                    </Row> 
                                    <Row style={{marginTop: 24, marginBottom: 44}}>
                                        {
                                            !verCargando ?
                                            <>
                                            <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6} style={{alignItems: 'center', alignContent: 'center', textAlign: 'center', display: 'grid'}}>
                                                <button className="btn-rechazar" onClick={() => pressBtn(false)}>Rechazar</button>
                                            </Col>
                                            <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6} style={{alignItems: 'center', alignContent: 'center', textAlign: 'center', display: 'grid'}}>
                                                <button className="btn-aceptar" onClick={() => pressBtn(true)}>Aceptar</button>
                                            </Col>
                                            </>
                                            :
                                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{alignItems: 'center', alignContent: 'center', textAlign: 'center', display: 'grid'}}>
                                                <ReactLoading type={'spin'} color={'#1D4999'} height={'10%'} width={'10%'} />
                                            </Col>
                                        }
                                        
                                    </Row>                                     
                                </Container>
                            </header>
                        </div>
                </>
            }
        </>
    );
}

export default ContentimientoScreen;